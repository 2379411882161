<template>
    <v-row class="fill-height" style="place-content: center">
        <v-col cols="12" class="mt-2">

            <div class="d-flex justify-center align-center">
                <div class="text-center">
                    <img src="imgs/latte_main.png"
                         width="120px"
                         height="fit-content"
                    />

                    <h5 class="mt-6"> 24시간 언제든지 연락주세요</h5>
                    <h1 class="display-1 font-weight-bold mt-3"> 제휴 및 가맹</h1>

                    <div class="d-flex mt-7 justify-space-between pl-1 pr-1">
                        <!-- <a href="tel:010-3100-1777">
                            <img src="imgs/tel.png"
                                 width="55px"
                                 height="55px"
                            />
                        </a> -->

                        <a href="mailto:onethecon@naver.com">
                            <img src="imgs/mail.png"
                                 width="55px"
                                 height="55px"
                            />
                        </a>

                        <a href="http://kakao.com/talk/mothcar">
                            <img src="imgs/kako.png"
                                 width="55px"
                                 height="55px"
                            />
                        </a>
                    </div>

                    <p class="mt-5 grey--text">
                        서울시 서초구 서초동<br>
                        1628-8
                    </p>

                </div>
            </div>

        </v-col>
    </v-row>
</template>

<script>
    // import axios from '../../service/axios'

    export default {
        name: 'Partner',
        components: {

        },
        data(){
            return {

            }
        },
        created () {

        },
        methods: {

        },
    }

</script>

<style lang="scss">
    .preview_img{
        .v-image__image--cover {
            background-size: contain;
        }
    }
    .preview{
        .v-btn--icon.v-size--small{
            width: 10px;
            height: 10px;
        }
    }
    .subheader{
        font-size: 0.85rem;
        align-self: center;
    }
</style>
